<!-- [style]="{ width: '80vw', min-width: '1050px' }" -->

<p-dialog (onHide)="closePopup()" header="Header" [dismissableMask]="true" [modal]="true" [(visible)]="visible" [style]="{ width: '75vw' }">
  <ng-template pTemplate="header">
    @if (mediaVisualisationFromPage === true) {
      <h2 class="text-xl font-bold">Visualiser un média</h2>
    } @else {
      <h2 class="text-xl font-bold">Associez des médias</h2>
    }
  </ng-template>
  
  @if (mediaVisualisation === true || mediaVisualisationFromPage === true) {
    <div class="horizontal-display">
      <div class="media-container">
        @switch (mediaToDisplay?.media?.fileType) {
          @case (enumFileType.Pdf) {
            <button class="button" (click)="downloadPdf()">Télécharger le pdf</button>
          }
          @case (enumFileType.Video) {
            <video #video controls (loadeddata)="displayDimensionsVideo()">
              <source [src]="fileUrl" type="video/quicktime" />
              <source [src]="fileUrl" type="video/mp4" />
            </video>
          }
          @case (enumFileType.Image) {
            @if (mediaIsLoading) {
              <p-progressSpinner></p-progressSpinner>
            } @else {
              <img #image [src]="fileUrl" alt="image" (load)="displayDimensionsImage()"/>
            }
          }
          @case (enumFileType.Audio) {
            <audio controls [src]="fileUrl"></audio>
          }
        }
      </div>

      <div class="border"></div>

      <div class="infos-media">
        <h3>Informations du média</h3>
        <p>Nom: <strong>{{ mediaToDisplay?.media?.name }}</strong></p>
        <p>Extension: <strong>{{ mediaToDisplay?.media?.extension | lowercase }}</strong></p>
        <p>Type: <strong>{{ displayFileType(mediaToDisplay?.media?.fileType || 0) }}</strong></p>
        @if ((mediaIsLoading === false && mediaToDisplay?.media?.fileType === enumFileType.Image) || mediaToDisplay?.media?.fileType === enumFileType.Video) {
          <p>Dimensions: <strong>{{ dimensionsMedia[0] + ' x ' + dimensionsMedia[1] }}</strong></p>
        }
      </div>
    </div>
  } @else {
    <p-table
      #mediaTable
      [value]="filteredMediaList"
      [rows]="7"
      [selectionMode]="selectionMode"
      [(selection)]="currentSelection"
      [paginator]="true"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{first} à {last}, {totalRecords} médias"
      paginatorDropdownAppendTo="body"
      [rowsPerPageOptions]="[7, 10, 15]"
      (selectionChange)="onSelectionChange($event)"
      (onPage)="onRowsPerPageChange($event)">

      <ng-template pTemplate="caption">
        <div class="flex">
          <input 
            pInputText
            [(ngModel)]="filterValue"
            type="text" 
            (input)="applyGlobalFilter()"
            placeholder="Rechercher"
            type="search" />
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="name">Nom <p-sortIcon field="name"></p-sortIcon></th>
          <th pSortableColumn="createdAt">Crée le <p-sortIcon field="createdAt"></p-sortIcon></th>
          <th style="width: 8rem">Type</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-media>
        <tr class="table" [pSelectableRow]="media" [attr.data-id]="media.id">
          <td>{{ media.name }}{{ media.extension | lowercase }}</td>
          <td>{{ media.createdAt | date: "short" }}</td>
          <td>{{ displayFileType(media.fileType) }}</td>
          <td>
            <i tabIndex="0" aria-hidden="true" class="bi bi-eye action-buttons-item" title="Visualiser le média" (click)="visualizeMedia(media)"> </i>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="5">Aucun média n'est enregistré dans l'application.</td>
        </tr>
      </ng-template>
    </p-table>
  }

  <ng-template pTemplate="footer">
    @if (mediaVisualisationFromPage === true) {
      <button class="button" (click)="closePopup()">Retour</button>
    } @else if (mediaVisualisation === true) {
      <button class="button" (click)="returnToList()">Retour</button>
    } @else {
      <div class="selection-list">
         {{displaySelectedCount()}}
         @if (selectionMode === 'single' && selectedMedia) {
          <li tabindex="0" (click)="goToPageContainingFile(selectedMedia)" (keypress)="goToPageContainingFile(selectedMedia)">{{ selectedMedia.name }}</li>
         } @else {
          @for (file of selectedMediaList; track $index)
          {
            <li tabindex="0" (click)="goToPageContainingFile(file)" (keypress)="goToPageContainingFile(file)">{{ file.name }}</li>
          }
         }
        
      </div>
      <button class="button" [ngClass]="selectedMedia || selectedMediaList.length ? null : 'button-disabled'" (click)="associateMedia()">
        <span>Associer</span>
      </button>
      <button class="button" (click)="closePopup()">Annuler</button>
    }
  </ng-template>
</p-dialog>
