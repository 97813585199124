import { Component, EventEmitter, Input, Output } from '@angular/core';
import { entityType } from 'app/Enumerations/entity-type.enum';
import { getPublishStateValue, publishState } from 'app/Enumerations/publish-state.enum';
import { PointOfInterest } from 'app/Models/point-of-interest';
import { AuthService } from 'app/Services/Auth-Service/auth.service';

@Component({
  selector: 'app-point-of-interest-card',
  templateUrl: './point-of-interest-card.component.html',
  styleUrls: ['./point-of-interest-card.component.css'],
})
export class PointOfInterestCardComponent {
  @Input() pointOfInterest!: PointOfInterest;

  @Output() deletePointOfInterestEmitter: EventEmitter<PointOfInterest> = new EventEmitter<PointOfInterest>();
  @Output() dragStartedEmitter: EventEmitter<number[]> = new EventEmitter<number[]>();

  //Enumerations
  enumPublishState = publishState;
  enumEntityType = entityType

  constructor(private authService: AuthService) {}

  deletePointOfInterest() {
    this.deletePointOfInterestEmitter.emit(this.pointOfInterest);
  }

  dragStarted(event: MouseEvent) {
    event.preventDefault();
    this.dragStartedEmitter.emit([event.clientY, event.offsetY, this.pointOfInterest.order - 1]);
  }

  displayPointOfInterestState() {
    return getPublishStateValue(this.pointOfInterest.state);
  }

  getProjectName(): string {
    return this.authService.getProjectName();
  }
}
