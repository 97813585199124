<div [style.height.px]="getHeightCard()">
  <button (click)="show()">show()</button>
  <div class="header">
    <div class="horizontal-display">
      <h2>Associez des médias</h2>
      <app-completion-state
        class="margin-left"
        [completionState]="associatedMediaList.length > 0"
        [tooltipTrue]="'Les médias associés sont valides'"
        [tooltipFalse]="'Aucun média n\'est associé à l\'activité'">
      </app-completion-state>
    </div>
    <i tabIndex="0" aria-hidden="true" class="bi bi-plus-circle-dotted action-buttons-item" title="Associer un nouveau média" (click)="showPopupV2()"> </i>
  </div>

  <app-associated-media-list
    [multiLanguageForm]="multiLanguageForm"
    [associatedMediaList]="associatedMediaList"
    [mediaForm]="mediaForm"
    [items]="items"
    (showAssociatedMediaEmitter)="showPopup($event, true)"
    (deleteAssociatedMediaEmitter)="removeMedia($event)"
    (renameMediaAssociationEmitter)="saveMediaNameTranslation($event)">
  </app-associated-media-list>
</div>

<app-media-association
  [visible]="isPopupVisible"
  [mediaList]="mediaList"
  [filteredMediaList]="mediaList"
  [mediaVisualisationFromPage]="mediaVisualisationFromPage"
  [mediaToDisplay]="associatedMediaList[indexPopupOpened]"
  [fileUrl]="fileUrl"
  [fileBlob]="fileBlob"
  [selectionMode]="'multiple'"
  (closePopupEmitter)="closePopup()"
  (associatedMediaEmitter)="associateMedia($event)">
</app-media-association>
