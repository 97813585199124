<div class="card">
  <form [formGroup]="moduleForm">
    <h2 title="Cette section sert à configurer l'activité.">Configuration de l'activité<i class="bi bi-question-circle infos-title-section margin-left-small"></i></h2>

    <div class="horizontal-display">
      <div class="form-field">
        <span class="">
          <label htmlFor="moduleLabel">Label de l'activité</label>
          <p>Choisissez un nom qui ne sera visible que par vous pour identifier l'activité</p>
          <input [class.ng-invalid]="isModuleDirty()" 
            class="ng-invalid ng-dirty" 
            pInputText 
            id="moduleLabel" 
            formControlName="moduleLabel" 
            [maxlength]="inputLength.maxModuleLabel"
            (change)="saveModuleLabel()"/>
            <small class="char-count">
              ({{ countLabelCharacters() }}/{{ inputLength.maxModuleLabel }})
            </small>
          @if (isModuleLabelInvalid()) {
            <p class="red-font">Le nom du module doit être inférieur à {{ inputLength.maxModuleLabel }} caractères.</p>
          }
        </span>
      </div>
    
      <div class="form-field">
        <span class="">
          <label htmlFor="moduleLabel">Type d'activité</label>
          <p></p>
          <input pInputText id="disabled-input" [disabled]="true" [value]="displayModuleType()" />
        </span>
      </div>
    </div>
    <div class="horizontal-display">
      <div class="form-field">
        <span class="">
          <label htmlFor="moduleLabel">Étape associée</label>
          <p>Associez l'activité à une étape</p>
          <p-dropdown
            [options]="pointOfInterestList"
            optionValue="id"
            optionLabel="identificationName"
            placeholder="Selectionnez une étape"
            formControlName="associatedPOI"
            filter="true"
            scrollHeight="400px"
            (onChange)="pointOfInterestAssociated($event.value)">
          </p-dropdown>
          <i tabIndex="0" aria-hidden="true" class="bi bi-x-lg action-buttons-item" title="Supprimer l'association étape/activité" (click)="removePointOfInterestAssociation()"> </i>
        </span>
      </div>
      <div class="form-field">
        <span class="">
          <label htmlFor="languages">Langue(s) de l'activité</label>
          <p>Choisissez les langues qui seront disponibles sur la tablette de l'utilisateur</p>
          <p-multiSelect
            [showHeader]="false"
            id="languages-multiselect"
            formControlName="languages"
            [options]="languagesList"
            optionValue="id"
            optionLabel="name"
            [filter]="false"
            (onChange)="configurationLanguageChange($event)">
          </p-multiSelect>
        </span>
      </div>
    </div>
  </form>
</div>
