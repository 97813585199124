import { Component, Input } from '@angular/core';
import { entityType } from 'app/Enumerations/entity-type.enum';
import { getPublishStateValue, publishState } from 'app/Enumerations/publish-state.enum';

@Component({
  selector: 'app-publication-state',
  templateUrl: './publication-state.component.html',
  styleUrl: './publication-state.component.css'
})
export class PublicationStateComponent {

  tooltip: string = "État de publication ";

  enumPublishState = publishState;

  @Input() publicationState!: publishState;
  @Input() entityType!: entityType;

  displayPointOfInterestState() {
    return getPublishStateValue(this.publicationState);
  }

  displayTooltip() {
    let entityTypeString: string;
    switch (this.entityType) {
      case entityType.Itinerary:
        entityTypeString = "du parcours";
        break;
      
      case entityType.PointOfInterest:
        entityTypeString = "de l'étape";
        break;

      case entityType.Module:
        entityTypeString = "de l'activité";
        break;

      default:
        entityTypeString = ""
        break;
    }
    return this.tooltip + entityTypeString;
  }

  retrievePublicationStateStyleClass() {
    switch (this.publicationState) {
      case publishState.Published:
        return 'green-publication-state';
      case publishState.Modified:
        return 'orange-publication-state';
      // Default include all the states that need to be displayed in red (not published, publication in progress and unknown type)
      default:
        return 'red-publication-state';
    }
  }
}
