import { ChangelogItem } from 'app/Models/newFunctionalityChangelog';

// We update this list when we push our changes to the production branch. If you're pushing to prod you have to:
// - Make sure that the list is readable for our final user (non technical people).
// - Add the release date to the first element of the list.
// - Copy the model to the top of the list
//
// Whenever you develop a new functionality, you should add it in the corresponding list:
// - Added for new features.
// - Changed for changes in existing functionality.
// - Fixed for any bug fixes.
// - Removed for now removed features.
// Make sure that the functionality is readable for our final user (non technical people).
//
// We follow the Keep A Changelog model : https://keepachangelog.com/en/1.1.0/

/* MODEL TO COPY
{
  releaseDate: null,
  functionalities: {
    added: [] ,
    changed: [],
    fixed: [],
    removed: []
  }
},
*/

export const newFunctionalitiesList: ChangelogItem[] = [
  {
    releaseDate: null,
    functionalities: {
      added: [
        "Étape automatiquement remplie lorsqu'on crée une nouvelle activité à partir d'une étape existante",
        "Création d'un fil d'ariane sur les pages de gestion d'activité et d'étape",
        "Ajout d'une barre de recherche pour filtrer les listes de médias",
        "Ajout de la possibilité d'ajouter plusieurs médias en même temps lorsqu'on crée une activité du type galerie multimédia",
        "Lors de la sélection de médias, ajout d'une liste des médias actuellement sélectionnés, possibilité de cliquer sur un élément de la liste pour être redirigé sur la page où il est présent",
        "Ajout d'une barre de recherche pour filtrer la liste des activités",
        "Ajout d'une barre de recherche sur le menu déroulant des étapes dans la page de configuration des activités",
        "Lors de la création d'un nouveau parcours, la langue est automatiquement sélectionnée si il n'y en a qu'une définie pour le projet"
        ],
      changed: [
        "Lors de la modification du nom d'un média après un upload, on peut désormais valider en appuiyant sur entrée",
        "Agrandissement des menus déroulants pour sélectionner une étape ou un type d'activité spéciale",
        "Le nom de l'onglet du navigateur change désormais en fonction de la page ou l'on se trouve"
      ],
      fixed: [
        "On ne pouvait pas accèder à la page d'une activité depuis la liste des activités en appuiyant sur ctrl + clic ou clic   molette pour l'ouvrir dans un nouvel onglet, c'est désormais corrigé"
      ],
      removed: [],
    }
  },
];
