import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { publishState } from 'app/Enumerations/publish-state.enum';
import { Itinerary } from 'app/Models/itinerary';
import { AuthService } from 'app/Services/Auth-Service/auth.service';
import { DataService } from 'app/Services/Data-Service/data.service';
import { MessageService } from 'primeng/api';
import { throwError, Subscription } from 'rxjs';

@Component({
  selector: 'app-area-management-page',
  templateUrl: './area-management-page.component.html',
  styleUrls: ['./area-management-page.component.css'],
})
export class AreaManagementPageComponent implements OnInit, OnDestroy {
  listOfItineraries: Itinerary[] = [];
  dataReceived: boolean = false;

  //Enumerations
  enumPublishState = publishState;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private dataService: DataService,
    private authService: AuthService,
    private messageService: MessageService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.dataService.getItineraries().subscribe((res) => {
      this.listOfItineraries = res;
      this.dataReceived = true;

      this.listOfItineraries.forEach((itinerary) => {
        this.subscriptions.add(
          this.dataService.getItineraryState(itinerary.id).subscribe((response) => {
            const indexItinerary = this.listOfItineraries.map((e) => e.id).indexOf(itinerary.id);
            if (this.listOfItineraries[indexItinerary].state === this.enumPublishState.PublicationInProgress && response === this.enumPublishState.Published) {
              this.listOfItineraries[indexItinerary].state = response;
            }
          }),
        );
      });
    });
    this.setPageTitle();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  deleteItinerary(itineraryId: number) {
    const itineraryIndex = this.listOfItineraries.map((e) => e.id).indexOf(itineraryId);

    if (this.listOfItineraries[itineraryIndex].state !== this.enumPublishState.PublicationInProgress) {
      this.dataService.deleteItinerary(itineraryId).subscribe({
        next: () => {
          this.messageService.clear();
          this.messageService.add({ severity: 'success', summary: 'Suppression réussie', detail: 'Le parcours a bien été supprimé.' });
          this.listOfItineraries = this.listOfItineraries.filter((itineraryFromList) => itineraryFromList.id !== itineraryId);
        },
        error: (error) => {
          this.handleError(error);
        },
      });
    } else {
      this.messageService.add({ severity: 'error', summary: 'Suppression impossible', detail: "Il n'est pas possible de supprimer un parcours qui est en cours de publication." });
    }
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(`Backend returned code ${error.status}, body was: `, error.error);
      if (error.status == 403) {
        this.messageService.add({ severity: 'error', summary: 'Suppression non autorisée', detail: 'Vous ne disposez pas des droits nécessaires pour effectuer cette action.' });
      }
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }

  combineItineraries() {
    this.dataService.getPublishedItineraries('65256e87b9b80e5acbb05b38').subscribe({
      next: () => {
        /* display the slist, removed for server deployment */
      },
      error: (error) => {
        this.handleError(error);
      },
    });
  }

  getProjectName(): string {
    return this.authService.getProjectName();
  }

  setPageTitle() {
    document.title = "Parcours";
  }
}
