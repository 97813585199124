import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { displayValue, fileType } from 'app/Enumerations/file-type.enum';
import { Media } from 'app/Models/media';
import { MediaAssociation } from 'app/Models/module';
import { DataService } from 'app/Services/Data-Service/data.service';
import { MessageService } from 'primeng/api';
import { Table, TablePageEvent } from 'primeng/table';


@Component({
  selector: 'app-media-association',
  templateUrl: './media-association.component.html',
  styleUrl: './media-association.component.css',
})
export class MediaAssociationComponent implements OnChanges {
  @Input() visible: boolean = false;
  @Input() mediaList: Media[] = [];
  @Input() filteredMediaList: Media[] = [];
  @Input() mediaVisualisationFromPage: boolean = false;
  @Input() mediaToDisplay?: MediaAssociation;

  @Input() fileBlob?: Blob;
  @Input() fileUrl?: string;
  @Input() selectionMode: 'single' | 'multiple' = "single";

  mediaVisualisation: boolean = false;

  @Output() closePopupEmitter: EventEmitter<void> = new EventEmitter<void>();
  @Output() associatedMediaEmitter: EventEmitter<Media> = new EventEmitter<Media>();

  @ViewChild('video') videoReader!: ElementRef;
  @ViewChild('image') imageReader!: ElementRef;
  @ViewChild('mediaTable') mediaTable!: Table;

  selectedMediaList: Media[] = [];
  selectedMedia?: Media;
  currentSelection?: Media | Media[] = this.selectedMedia;

  filterValue: string = '';

  rowsPerPage: number = 7;
  lastMediaHighlightedId?: number;

  mediaIsLoading: boolean = true;

  dimensionsMedia: number[] = [];

  //Forms
  checkBoxForm: FormGroup;

  //Enumerations
  enumFileType = fileType;

  constructor(
    private fb: FormBuilder,
    private dataService: DataService,
    private messageService: MessageService,
    private datePipe: DatePipe
  ) {
    this.checkBoxForm = this.fb.group({
      isChecked: [false],
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['fileBlob']) {
      if (changes['fileBlob'].currentValue !== null && changes['fileBlob'].currentValue !== undefined) {
        this.mediaIsLoading = false;
      }
      if (this.fileBlob && this.mediaToDisplay?.media.fileType == this.enumFileType.Video) {
        this.videoReader.nativeElement.src = this.fileUrl;
      }
    }
    this.removeHighlightedRow();
  }

  applyGlobalFilter() {
    this.filteredMediaList = this.mediaList;
    this.filteredMediaList = this.filteredMediaList.filter((item) => {
      return this.matchesFilter(item, this.filterValue);
    });
  }

  matchesFilter(item: Media, filterValue: string): boolean {
    filterValue = filterValue.toLowerCase();

    // TODO: maybe we should cache these values when initing the modules
    // This is wasted performance to redo all the transform/lowercase every time
    const formattedDate = this.datePipe.transform(item.createdAt, 'short');
    const nameMatches = item.name.toLowerCase().includes(filterValue);
    const dateMatches = formattedDate?.includes(filterValue)
    const fileTypeMatches = displayValue(item.fileType).toLowerCase().includes(filterValue);
    const extensionMatches = item.extension.toLowerCase().includes(filterValue);

    return nameMatches || dateMatches || fileTypeMatches || extensionMatches;
  }

  visualizeMedia(media: Media) {
    this.mediaVisualisation = true;
    this.mediaIsLoading = true;
    this.mediaToDisplay = new MediaAssociation(0, media, false, 0, false, []);
    
    if (media.fileType === fileType.Image || media.fileType === fileType.Pdf){
      this.dataService.getFile(media).subscribe({
        next: (file) => {
          this.fileBlob = file;
          this.fileUrl = URL.createObjectURL(file);
          this.mediaIsLoading = false;
        },
        error: (error) => {
          console.log(error.message);
        },
      });
    } else {
      this.fileUrl = this.dataService.streamFile(media);
    }
  }

  displayFileType(type: number): string {
    return displayValue(type);
  }

  displayDimensionsImage(): void {
    this.dimensionsMedia[0] = this.imageReader.nativeElement.naturalWidth;
    this.dimensionsMedia[1] = this.imageReader.nativeElement.naturalHeight;
  }

  displayDimensionsVideo(): void {
    this.dimensionsMedia[0] = this.videoReader.nativeElement.videoWidth;
    this.dimensionsMedia[1] = this.videoReader.nativeElement.videoHeight;
  }

  downloadPdf() {
    const link = document.createElement('a');
    if (this.fileUrl && this.mediaToDisplay) {
      link.href = this.fileUrl;
      link.download = this.mediaToDisplay?.media.name + this.mediaToDisplay?.media.extension;
      link.click();
      link.remove();
    }
  }

  returnToList() {
    this.mediaVisualisation = false;
    this.fileBlob = undefined;
    this.fileUrl = undefined;
    this.mediaIsLoading = false;
  }

  onSelectionChange(event: Media | Media[]) {
    if (this.selectionMode === 'single') {
      this.selectedMedia = event as Media;
    } else {
      this.selectedMediaList = event as Media[];
    }
    this.removeHighlightedRow();
  }

  displaySelectedCount() {
    let countMessage: string = '';
      if (this.selectionMode === 'single' && this.selectedMedia || this.selectionMode === 'multiple' && this.selectedMediaList.length === 1) {
        countMessage += '1 fichier sélectionné';
      } else if (this.selectionMode === 'multiple' && this.selectedMediaList.length) {
        countMessage += this.selectedMediaList.length.toString() + ' fichiers sélectionnés';
      } else {
        countMessage += 'Aucun fichier sélectionné'
      }
    return countMessage;
  }

  goToPageContainingFile(file: Media) {
    this.removeHighlightedRow();
    const index = this.filteredMediaList.findIndex((f) => f.id === file.id);
    if (index !== -1) {
      const page = Math.floor(index / this.rowsPerPage);
      this.mediaTable.first = page * this.rowsPerPage;
      setTimeout(() => {
        const rowElement = document.querySelector(`tr[data-id="${file.id}"]`);
        if (rowElement) {
          rowElement.classList.add('highlighted-row');
          this.lastMediaHighlightedId = file.id;
        }
      }, 0);
    }
  }

  removeHighlightedRow() {
    if (this.lastMediaHighlightedId) {
      setTimeout(() => {
        const rowElement = document.querySelector(`tr[data-id="${this.lastMediaHighlightedId}"]`);
        if (rowElement) {
          rowElement.classList.remove('highlighted-row');
        }
      }, 0);
    }
  }

  onRowsPerPageChange(event: TablePageEvent) {
    this.rowsPerPage = event.rows;
  }

  associateMedia() {
    if (this.selectionMode === 'multiple' && this.selectedMediaList.length) {
      this.selectedMediaList.forEach(element => {
        this.associatedMediaEmitter.emit(element);
      });
      this.closePopup();
    } else if (this.selectionMode === 'single' && this.selectedMedia) {
      this.associatedMediaEmitter.emit(this.selectedMedia);
      this.closePopup();
    } else {
    this.messageService.add({ severity: 'warn', summary: 'Aucun média séletionné', detail: 'Sélectionnez un média pour effectuer cette action.' });
    }
  }

  closePopup() {
    this.closePopupEmitter.emit();
    this.initializePopup();
  }

  initializePopup() {
    this.mediaVisualisation = false;
    this.mediaToDisplay = undefined;
    this.fileBlob = undefined;
    this.fileUrl = undefined;
    this.selectedMedia = undefined;
    this.mediaIsLoading = true;
    this.dimensionsMedia = [];
    this.filteredMediaList = this.mediaList;
    this.filterValue = '';
    this.currentSelection = undefined;
    this.selectedMedia = undefined;
    this.selectedMediaList = [];
  }
}
