import { HttpClient, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Observable, throwError, interval, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';
import { ItineraryDetail } from 'app/Models/itineraryDetail';
import { environment } from 'environments/environment';
import { PointOfInterest } from 'app/Models/point-of-interest';
import { Itinerary } from 'app/Models/itinerary';
import { PointOfInterestDetail } from 'app/Models/PointOfInterestDetail';
import { User } from 'app/Models/user';
import { Media } from 'app/Models/media';
import { Module, ModuleCustomAssociation, ModuleCustomTemplate } from 'app/Models/module';
import { ItineraryPublication } from 'app/Models/publication';
import { HomeDashboard } from 'app/Models/homeDashboard';
import { ApiResponse } from 'app/Models/apiResponse';
import { moduleType } from 'app/Enumerations/module-type.enum';
import { BuildPlatform, Project } from 'app/Models/project';
import { Language } from 'app/Models/language';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(private http: HttpClient) {}

  //Itineraries
  createItinerary(itinerary: ItineraryDetail): Observable<ItineraryDetail> {
    return this.http.post<ItineraryDetail>(`${environment.apiUrl}/api/Itinerary/CreateItinerary`, itinerary);
  }

  getItineraries(): Observable<Itinerary[]> {
    return this.http.get<Itinerary[]>(`${environment.apiUrl}/api/Itinerary/GetItineraries`);
  }

  getItinerary(id: number): Observable<ItineraryDetail> {
    return this.http.get<ItineraryDetail>(`${environment.apiUrl}/api/Itinerary/GetItinerary/${id}`);
  }

  getItineraryState(id: number): Observable<number> {
    return interval(5000) // Modifier cette valeur pour définir la fréquence des appels API
      .pipe(switchMap(() => this.http.get<number>(`${environment.apiUrl}/api/Itinerary/GetItineraryState/${id}`)));
  }

  updateItinerary(itinerary: ItineraryDetail): Observable<ApiResponse<ItineraryDetail>> {
    return this.http.put<ApiResponse<ItineraryDetail>>(`${environment.apiUrl}/api/Itinerary/UpdateItinerary/${itinerary.id}`, itinerary);
  }

  deleteItinerary(itineraryId: number): Observable<ApiResponse<string>> {
    return this.http.delete<ApiResponse<string>>(`${environment.apiUrl}/api/Itinerary/DeleteItinerary/${itineraryId}`);
  }

  //Points of Interest
  createPointOfInterest(pointOfInterest: PointOfInterestDetail): Observable<PointOfInterestDetail> {
    return this.http.post<PointOfInterestDetail>(`${environment.apiUrl}/api/PointOfInterestAPI`, pointOfInterest);
  }

  deletePointOfInterest(itineraryId: number): Observable<ApiResponse<string>> {
    return this.http.delete<ApiResponse<string>>(`${environment.apiUrl}/api/PointOfInterestAPI/${itineraryId}`);
  }

  getAllPointsOfInterest(): Observable<PointOfInterest[]> {
    return this.http.get<PointOfInterest[]>(`${environment.apiUrl}/api/PointOfInterestAPI`);
  }

  getPointsOfInterest(itineraryId: string): Observable<PointOfInterest[]> {
    return this.http.get<PointOfInterest[]>(`${environment.apiUrl}/api/PointOfInterestAPI/${itineraryId}`);
  }

  getPointOfInterest(pointOfInterestId: string): Observable<PointOfInterestDetail> {
    return this.http.get<PointOfInterestDetail>(`${environment.apiUrl}/api/PointOfInterestAPI/${pointOfInterestId}`);
  }

  updatePointOfInterest(pointOfInterest: PointOfInterestDetail): Observable<ApiResponse<PointOfInterestDetail>> {
    return this.http.put<ApiResponse<PointOfInterestDetail>>(`${environment.apiUrl}/api/PointOfInterestAPI/${pointOfInterest.id}`, pointOfInterest);
  }

  //Medias
  deleteMedia(mediaId: number): Observable<ApiResponse<string>> {
    return this.http.delete<ApiResponse<string>>(`${environment.apiUrl}/api/Media/DeleteMedia/${mediaId}`);
  }

  getMedias(): Observable<Media[]> {
    return this.http.get<Media[]>(`${environment.apiUrl}/api/Media/GetMedias/`);
  }

  getFile(media: Media): Observable<Blob> {
    return this.http.get(`${environment.apiUrl}/api/Media/GetFile/${media.id}`, { responseType: 'blob' });
  }

  streamFile(media: Media): string {
    return `${environment.apiUrl}/api/Media/StreamFile/${media.id}`;
  }

  uploadMedia(file: FormData): Observable<HttpEvent<Media>> {
    return this.http.post<Media>(`${environment.apiUrl}/api/Media/UploadFile/`, file, {
      reportProgress: true,
      observe: 'events',
    });
  }

  updateMediaName(media: Media): Observable<Media> {
    return this.http.put<Media>(`${environment.apiUrl}/api/Media/UpdateFileName/${media.id}`, media);
  }
  
  getEntitiesAssociatedToMedia(mediaId: number): Observable<Module[]> {
    return this.http.get<Module[]>(`${environment.apiUrl}/api/Media/GetEntitiesAssociatedToMedia/${mediaId}`);
  }

  //Modules
  createModule(module: Module): Observable<Module> {
    switch (module.type) {
      case moduleType.Gallery:
        return this.createModuleGallery(module);
      case moduleType.QuizTrueFalse:
        return this.createModuleQuizTrueFalse(module);
      case moduleType.QuizMultipleChoice:
        return this.createModuleQuizMultipleChoice(module);
      case moduleType.QuizIncremental:
        return this.createModuleQuizIncremental(module);
      case moduleType.Custom:
        return this.createModuleCustom(module);
      case moduleType.Undefined:
        throw Error('ModuleTypeUnknown');
    }
  }

  createModuleQuizTrueFalse(module: Module): Observable<Module> {
    return this.http.post<Module>(`${environment.apiUrl}/api/Module/CreateModuleQuizTrueFalse`, module);
  }

  createModuleQuizMultipleChoice(module: Module): Observable<Module> {
    return this.http.post<Module>(`${environment.apiUrl}/api/Module/CreateModuleQuizMultipleChoice`, module);
  }

  createModuleQuizIncremental(module: Module): Observable<Module> {
    return this.http.post<Module>(`${environment.apiUrl}/api/Module/CreateModuleQuizIncremental`, module);
  }

  createModuleGallery(module: Module): Observable<Module> {
    return this.http.post<Module>(`${environment.apiUrl}/api/Module/CreateModuleGallery`, module);
  }

  createModuleCustom(module: Module): Observable<Module> {
    return this.http.post<Module>(`${environment.apiUrl}/api/Module/CreateModuleCustom`, module);
  }

  createModuleCustomTemplate(moduleCustomTemplate: ModuleCustomTemplate): Observable<ModuleCustomTemplate> {
    return this.http.post<ModuleCustomTemplate>(`${environment.apiUrl}/api/Module/CreateModuleCustomTemplate`, moduleCustomTemplate);
  }

  deleteModule(moduleId: number): Observable<ApiResponse<string>> {
    return this.http.delete<ApiResponse<string>>(`${environment.apiUrl}/api/Module/DeleteModule/${moduleId}`);
  }

  deleteModules(moduleIds: number[]): Observable<ApiResponse<number[]>> {
    return this.http.delete<ApiResponse<number[]>>(`${environment.apiUrl}/api/Module/DeleteModules`, { body: moduleIds });
  }

  getModules(): Observable<Module[]> {
    return this.http.get<Module[]>(`${environment.apiUrl}/api/Module/GetModules`);
  }

  getModuleCustomList(): Observable<ModuleCustomTemplate[]> {
    return this.http.get<ModuleCustomTemplate[]>(`${environment.apiUrl}/api/Module/GetModuleCustomList`);
  }

  getModule(moduleId: string): Observable<Module> {
    return this.http.get<Module>(`${environment.apiUrl}/api/Module/GetModule/${moduleId}`);
  }

  getModuleCustomTemplates() : Observable<ModuleCustomTemplate[]> {
    return this.http.get<ModuleCustomTemplate[]>(`${environment.apiUrl}/api/Module/GetModuleCustomTemplates`);
  }

  getModuleGallery(moduleId: string): Observable<Module> {
    return this.http.get<Module>(`${environment.apiUrl}/api/Module/GetModuleGallery/${moduleId}`);
  }

  updateModule(module: Module): Observable<ApiResponse<Module>> {
    switch (module.type) {
      case moduleType.Gallery:
        return this.updateModuleGallery(module);
      case moduleType.QuizTrueFalse:
        return this.updateModuleQuizTrueFalse(module);
      case moduleType.QuizMultipleChoice:
        return this.updateModuleQuizMultipleChoice(module);
      case moduleType.QuizIncremental:
        return this.updateModuleQuizIncremental(module);
      case moduleType.Custom:
        return this.updateModuleCustom(module);
      case moduleType.Undefined:
        throw Error('ModuleTypeUnknown');
    }
  }

  updateModuleGallery(module: Module): Observable<ApiResponse<Module>> {
    return this.http.put<ApiResponse<Module>>(`${environment.apiUrl}/api/Module/UpdateModuleGallery/${module.id}`, module);
  }

  // We might need to change the naming once we have another quiz
  // Not sure for now if Quiz and QuizTrueFalse will call the same backend route
  updateModuleQuizTrueFalse(module: Module): Observable<ApiResponse<Module>> {
    return this.http.put<ApiResponse<Module>>(`${environment.apiUrl}/api/Module/UpdateModuleQuizTrueFalse/${module.id}`, module);
  }

  updateModuleQuizMultipleChoice(module: Module): Observable<ApiResponse<Module>> {
    return this.http.put<ApiResponse<Module>>(`${environment.apiUrl}/api/Module/UpdateModuleQuizMultipleChoice/${module.id}`, module);
  }

  updateModuleQuizIncremental(module: Module): Observable<ApiResponse<Module>> {
    return this.http.put<ApiResponse<Module>>(`${environment.apiUrl}/api/Module/UpdateModuleQuizIncremental/${module.id}`, module);
  }

  updateModuleCustom(module: Module): Observable<ApiResponse<Module>> {
    return this.http.put<ApiResponse<Module>>(`${environment.apiUrl}/api/Module/UpdateModuleCustom/${module.id}`, module);
  }

  updateModuleCustomAssociations(moduleAssociations: ModuleCustomAssociation[]): Observable<any> {
    return this.http.put(`${environment.apiUrl}/api/Module/updateModuleCustomAssociations/`, moduleAssociations);
  }

  //Publication
  publishItinerary(id: number) {
    return this.http.get<ItineraryPublication>(`${environment.apiUrl}/api/Publication/PublishItinerary/${id}`);
  }

  getPublishedItineraries(id: string) {
    return this.http.get<ItineraryPublication[]>(`${environment.apiUrl}/api/Publication/CombinePublishedItineraries/${id}`);
  }

  //Home Dashboard
  getHomeDashboard() {
    return this.http.get<HomeDashboard>(`${environment.apiUrl}/api/HomeDashboard/GetHomeDashboard/`);
  }

  //Project
  getProjects() {
    return this.http.get<Project[]>(`${environment.apiUrl}/api/Project/GetProjects/`);
  }

  createProject(project: Project): Observable<Project> {
    return this.http.post<Project>(`${environment.apiUrl}/api/Project/CreateProject/`, project);
  }

  updateProject(project: Project): Observable<Project> {
    return this.http.put<Project>(`${environment.apiUrl}/api/Project/UpdateProject/`, project);
  }

  // Build Plaftform
  getBuildPlatforms() {
    return this.http.get<BuildPlatform[]>(`${environment.apiUrl}/api/Project/GetBuildPlatforms/`);
  }

  //User
  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(`${environment.apiUrl}/api/User/GetUsers/`);
  }

  GetUsersAndTheirProjects(): Observable<User[]> {
    return this.http.get<User[]>(`${environment.apiUrl}/api/User/GetUsersAndTheirProjects/`);
  }

  createUser(user: User): Observable<User> {
    return this.http.post<User>(`${environment.apiUrl}/api/User/CreateUser/`, user);
  }

  //Language
  getLanguages(): Observable<Language[]> {
    return this.http.get<Language[]>(`${environment.apiUrl}/api/Language/GetLanguages/`)
  }

  //Error handling
  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(`Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }
}
