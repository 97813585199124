import { Component, EventEmitter, Input, Output, SimpleChanges, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { inputLength } from 'app/ConfigVariables/input-length';
import { Language } from 'app/Models/language';

@Component({
  selector: 'app-itinerary-configuration',
  templateUrl: './itinerary-configuration.component.html',
  styleUrls: ['./itinerary-configuration.component.css'],
})
export class ItineraryConfigurationComponent implements OnChanges {
  @Input() informationsForm!: FormGroup;
  @Input() selectedLanguages: Language[] = [];
  @Input() languagesList: Language[] = [];

  @Output() languageChangedEmitter: EventEmitter<number> = new EventEmitter<number>();

  visibleLanguages: boolean = false;
  languageIdToDelete: number = 0;

  inputLength = inputLength;

  constructor(private formBuilder: FormBuilder) {
    this.informationsForm = this.formBuilder.group({
      itineraryLabel: ['', Validators.required],
      languages: [''],
      isLocated: [''],
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['languagesList'] && this.languagesList.length === 1 && this.selectedLanguages.length === 0) {
      // Hack to fix a NG0100 error
      // There might be a better way to do this? Should we use @Input/@Ouptut with an event? to the languageList?
      // TODO: investigate this
      setTimeout(() => {
        this.languagesChanged([this.languagesList[0].id]);
      }, 0);
    }
  }

  languagesChanged(languageIds: number[]) {
    // TODO: clean up these condition if they will not be used
    if (languageIds.length == 0) {
      // console.log('liste vide');
    } else if (languageIds[0] == -1) {
      // console.log('juste fermer la liste');
    } else {
      languageIds.forEach((id) => {
        // const index = this.languagesList.map(l => l.id).indexOf(id);
        this.languageChangedEmitter.emit(id);
        // this.selectedLanguages.push(this.languagesList[index]);
      });
    }
    this.closePopup();
  }

  showPopupLanguageDeletion(idLanguage: number) {
    this.languageIdToDelete = idLanguage;
    this.visibleLanguages = true;
  }

  closePopup() {
    this.visibleLanguages = false;
    this.languageIdToDelete = 0;
  }

  countLabelCharacters(): string {
    if (this.informationsForm.get(['itineraryLabel'])?.value) {
      return this.informationsForm.get(['itineraryLabel'])?.value.length;
    } else {
      return '0';
    }
  }
}
