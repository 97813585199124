<div class="body">
  <div class="page">
    <div class="header">
      <div>
        <div class="poi-name-state">
          <h1 [title]="'Label de l\'étape : ' + pointOfInterest?.identificationName" class="truncate-one-line">{{ pointOfInterest?.identificationName || "Nouvelle étape" }}</h1>
          @if (pointOfInterest) {
            <app-publication-state class="publication-state-margin single-line" [publicationState]="pointOfInterest.state" [entityType]="enumEntityType.PointOfInterest" >
            </app-publication-state>
          }
        </div>

        @if (pointOfInterest?.publicationDate) {
          <p>Dernière publication: {{ pointOfInterest?.publicationDate | date: "medium" }}</p>
        }
        @if (pointOfInterest?.lastModificationDate) {
          <p>Dernière modification: {{ pointOfInterest?.lastModificationDate | date: "medium" }}</p>
        }
        
        @if (pointOfInterest?.itineraryName && pointOfInterest) {
          <div class = "breadcrumbs">
            <a href="{{this.getProjectName()}}/itinerary-management/{{pointOfInterest.itineraryId}}" title="Parcours associé : {{pointOfInterest.itineraryName}}"> {{pointOfInterest.itineraryName}} </a>
            <span> > </span>
            <a title="Étape : {{pointOfInterest.identificationName}}"> {{pointOfInterest.identificationName}} </a>
          </div>
        } @else if (!isCreation) {
          <div class="placeholder-space"></div>
        }
        
      </div>

      @if (isCreation) {
        <button class="button" (click)="createPointOfInterest()" title="Créer l'étape">Créer</button>
      } @else {
        <p-splitButton label="Sauvegarder" title="Sauvegarder l'étape" (onClick)="updatePointOfInterest()" [model]="menuItems"></p-splitButton>
      }
    </div>

    <app-point-of-interest-configuration [configurationForm]="configurationForm"> </app-point-of-interest-configuration>

    <app-point-of-interest-multi-language-content
      [multiLanguageForm]="multiLanguageForm"
      [LanguageInfosPointOfInterest]="pointOfInterestTranslations"
      [languagesSelected]="languagesFromItinerary"
      (languageChangedEmitter)="languageChanged($event)"
      (saveLanguageEmitter)="saveLanguage()">
    </app-point-of-interest-multi-language-content>

    <app-module-list
      [moduleList]="moduleList"
      [availableModuleList]="availableModuleList"
      [idPOI]="pointOfInterest?.id"
      (addModuleToPointOfInterest)="addModuleDroped($event)"
      (removeModuleToPointOfInterest)="removeModule($event)">
    </app-module-list>
  </div>
</div>

<app-confirmation-popup [visible]="visible" [confirmTypeAction]="confirmTypeAction" [entityTypeAction]="entityTypeAction" (confirmActionEmitter)="confirmActionDialog($event)">
</app-confirmation-popup>
