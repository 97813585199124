import { Component, OnInit } from '@angular/core';
import { newFunctionalitiesList } from 'app/Mocks/changelog-items';
import { ChangelogItem } from 'app/Models/newFunctionalityChangelog';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-changelog-page',
  templateUrl: './changelog-page.component.html',
  styleUrl: './changelog-page.component.css'
})
export class ChangelogPageComponent implements OnInit {
  changelogItems: ChangelogItem[] = [];
  unreleasedFuntionalitiesList: ChangelogItem[] = [];
  environment = environment;

  ngOnInit() {
    for (const item of newFunctionalitiesList) {
      if (item.releaseDate === null) {
        this.unreleasedFuntionalitiesList.push(item);
      } else {
        this.changelogItems.push(item);
      }
    }
    this.setPageTitle();
  }

  setPageTitle() {
    document.title = "Changelog";
  }
}
