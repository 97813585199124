<div>
  <form [formGroup]="mediaForm">
    @if (dragged) {
      <div (window:mouseup)="stop($event)" (window:mousemove)="move($event)"></div>
    }

    <div (window:scrollend)="onScrollEnd()"></div>

    @if (associatedMediaList.length !== 0) {
      <div class="horizontal-display">
        <div class="horizontal-display central-part">
          <p class="media-name">Label du média:</p>
          <p class="traduction">Nom sur l'application:</p>
          <p class="file-type">Type:</p>
        </div>
        <p class="actions-text">Actions:</p>
      </div>
    }

    <div class="item-container">
      @if (associatedMediaList.length === 0) {
        <p>Aucun média n'est associé à cette activité.</p>
      } @else {
        @for (mediaAssociation of associatedMediaList; track mediaAssociation; let indexOfElement = $index) {
          <app-associated-media-card
            #dragItem
            class="item"
            [ngClass]="indexOfElement === indexDraggedItem ? 'bring-to-front' : 'set-to-back'"
            [style.top.px]="indexOfElement * distanceBetweenCards"
            [mediaAssociation]="mediaAssociation"
            [formArrayIndex]="indexOfElement"
            [mediaForm]="mediaForm"
            [items]="items"
            [multiLanguageForm]="multiLanguageForm"
            (dragStartedEmitter)="start($event, indexOfElement)"
            (showAssociatedMediaEmitter)="showPopup($event)"
            (deleteAssociatedMediaEmitter)="removeMedia($event)"
            (renameMediaAssociationEmitter)="saveMediaNameTranslation($event)"
            (addTranslationsEmitter)="addTranslations($event)">
          </app-associated-media-card>
  
          @if (indexOfElement !== 0) {
            <div [style.top.px]="indexOfElement * distanceBetweenCards" class="horizontal-line"></div>
          }
        }
      }
    </div>
  </form>
</div>
