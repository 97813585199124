import { Injectable } from '@angular/core';
import { AnswerTranslation, MediaAssociation, MediaAssociationTranslation, Module, ModuleTranslation, Question, QuestionTranslation } from 'app/Models/module';
import { Observable, BehaviorSubject, tap } from 'rxjs';
import { DataService } from '../Data-Service/data.service';
import { moduleType } from 'app/Enumerations/module-type.enum';
import { publishState } from 'app/Enumerations/publish-state.enum';
import { Router } from '@angular/router';
import { AuthService } from '../Auth-Service/auth.service';
import { MessageService } from 'primeng/api';
import { ApiResponse } from 'app/Models/apiResponse';
import { responseCode } from 'app/Enumerations/response-code.enum';

@Injectable()
export class ModuleService {
  private module = new BehaviorSubject<Module>(new Module());
  private quizQuestions = new BehaviorSubject<Question[]>([]);
  private associatedMediaList = new BehaviorSubject<MediaAssociation[]>([]);
  private moduleCustomTemplateId = new BehaviorSubject<number>(-1);

  moduleObservable = this.module.asObservable();
  listOfTrueFalseQuestions = this.quizQuestions.asObservable();
  associatedMediaListObservable = this.associatedMediaList.asObservable();
  moduleCustomTemplateIdObservable = this.moduleCustomTemplateId.asObservable();

  // TODO: use Partial<Module> objects to separate data with the differents components
  // private moduleConfigInfos: BehaviorSubject<Partial<Module>> = new BehaviorSubject<Partial<Module>>({});

  //Enumerations
  enumModuleType = moduleType;


  constructor(private dataService: DataService,
    private authService: AuthService,
    private messageService: MessageService,
    private router: Router
  ) {}

  setQuizQuestions(questions: Question[]) {
    this.quizQuestions.next(questions);
  }

  getQuizQuestions(): Question[] {
    return this.quizQuestions.getValue();
  }

  setModule(module: Module) {
    this.module.next(module);
  }

  getModule(): Module {
    return this.module.getValue();
  }

  getModuleType(): number {
    return this.module.getValue().type;
  }

  setMediaAssociations(mediaAssociations: MediaAssociation[]) {
    this.associatedMediaList.next(mediaAssociations);
  }

  getMediaAssociations(): MediaAssociation[] {
    return this.associatedMediaList.getValue();
  }

  setModuleCustomTemplateId(id: number) {
    this.moduleCustomTemplateId.next(id);
  }

  getModuleCustomTemplateId(): number {
    return this.moduleCustomTemplateId.getValue();
  }

  fetchModule(moduleId: string) {
    this.dataService.getModule(moduleId).subscribe({
      next: (module) => {
        this.module.next(module);
        if ((module.type === this.enumModuleType.QuizTrueFalse || module.type === this.enumModuleType.QuizMultipleChoice || module.type === this.enumModuleType.QuizIncremental) && module.questions) {
          this.setQuizQuestions(module.questions);
        } else if (module.type === moduleType.Gallery && module.mediaAssociations) {
          this.associatedMediaList.next(module.mediaAssociations);
        } else if (module.type === moduleType.Custom && module.moduleCustomTemplateId) {
          this.moduleCustomTemplateId.next(module.moduleCustomTemplateId);
        }
      },
      error: (error) => {
        console.log(error.message);
      },
    });
  }

  initializeNewModule(moduleType: number) {
    const module: Module = new Module();
    module.type = moduleType;
    module.state = publishState.NotPublished;
    module.order = -1;
    module.readyForPublication = false;
    this.module.next(module);
  }

  resetModule() {
    this.module.next(new Module);
    this.associatedMediaList.next([]);
    this.moduleCustomTemplateId.next(-1);
    this.quizQuestions.next([]);
  }

  savePartialUpdate(partialModule: Partial<Module>) {
    const currentModule = this.module.getValue();
    const updatedModule = { ...currentModule, ...partialModule };
    this.module.next(updatedModule);
  }

  getModuleInfos(): Partial<Module> {
    const infos = {
      identificationName: this.module.getValue().identificationName,
      pointOfInterestId: this.module.getValue().pointOfInterestId,
    }
    return infos;
  }

  modifyLanguageInService(languageId: number, languageFromPoi: boolean) {
    const moduleTypeFromService = this.module.getValue().type;
    const quizQuestions = this.getQuizQuestions();
    const mediaAssociations = this.getMediaAssociations();

    // Modify languages for moduleName
    const partialModule: Partial<Module> = {
      moduleTranslations: this.module.getValue().moduleTranslations
    }
    if (partialModule.moduleTranslations) {
      const indexLanguage = partialModule.moduleTranslations.findIndex((e) => e.languageId === languageId);
      if (indexLanguage === -1) {
        partialModule.moduleTranslations?.push(new ModuleTranslation(this.module.getValue().id, languageId, '', ''));
        partialModule.moduleTranslations?.sort();
      } else {
        partialModule.moduleTranslations?.splice(indexLanguage, 1);
      }
      this.savePartialUpdate(partialModule);
    }

    // Modify languages for mediaAssociations
    if (moduleTypeFromService === moduleType.Gallery && mediaAssociations.length > 0) {
      const indexLanguage = mediaAssociations[0].mediaAssociationTranslations?.findIndex((e) => e.languageId === languageId);
      if (indexLanguage === -1) {
        mediaAssociations.forEach((association) => {
          association.mediaAssociationTranslations?.push(new MediaAssociationTranslation(0, languageId, ''));
        });
      } else {
        mediaAssociations.forEach((association) => {
          association.mediaAssociationTranslations?.splice(indexLanguage, 1);
        });
      }
      this.setMediaAssociations(mediaAssociations);
    }

    // Modify languages for questions
    if (moduleTypeFromService === moduleType.QuizTrueFalse || moduleTypeFromService === moduleType.QuizMultipleChoice || moduleTypeFromService === moduleType.QuizIncremental) {
      if (quizQuestions.length > 0 || moduleTypeFromService === this.enumModuleType.QuizIncremental) {
        const indexLanguage = quizQuestions[0].questionTranslations.findIndex((e) => e.languageId === languageId);

        if (indexLanguage === -1) {
          quizQuestions.forEach((question) => {
            question.questionTranslations.push(new QuestionTranslation(0, languageId, '', ''));

            if (moduleTypeFromService !== moduleType.QuizTrueFalse) {
              question.answers.forEach((answer) => {
                answer.answerTranslations.push(new AnswerTranslation(0, languageId, '', ''));
              });
            }
          });
        } else if (!languageFromPoi) {
          quizQuestions.forEach((question) => {
            question.questionTranslations.splice(indexLanguage, 1);

            question.answers.forEach((answer) => {
              answer.answerTranslations.splice(indexLanguage, 1);
            });
          });
        }
        this.setQuizQuestions(quizQuestions);
      }
    }
  }

  saveTranslation(languageId: number, moduleName: string, textSynthesis: string) {
    const partialModule: Partial<Module> = {
      moduleTranslations: this.module.getValue().moduleTranslations
    }
    if (partialModule.moduleTranslations) {
      const indexLanguageModifications = partialModule.moduleTranslations.findIndex(mt => mt.languageId === languageId);
      partialModule.moduleTranslations[indexLanguageModifications].name = moduleName;
      partialModule.moduleTranslations[indexLanguageModifications].synthesis = textSynthesis;
      this.savePartialUpdate(partialModule);
    }
  }

  isModuleValid() {
    return this.module.value.identificationName && this.module.value.identificationName.length > 0;
  }

  createModule(): Observable<Module> {
    const module: Module = this.getModule();

    if (module.type === moduleType.QuizMultipleChoice || module.type === moduleType.QuizTrueFalse || module.type === moduleType.QuizIncremental) {
      module.questions = this.getQuizQuestions();
      module.questions.forEach((question) => {
        question.mediaId = question.media?.id;
        question.media = undefined;
        if (module.type === moduleType.QuizMultipleChoice || module.type === moduleType.QuizIncremental) {
          question.answers.forEach((answer) => {
            answer.mediaId = answer.media?.id;
            answer.media = undefined;
          });
        }
      });
      module.mediaSynthesisId = module.mediaSynthesis?.id;
      module.mediaSynthesis = undefined;
    }

    switch (module.type) {
      case moduleType.Gallery: {
        module.mediaAssociations = this.getMediaAssociations();
        module.mediaAssociations.forEach((mediaAssociation) => {
          if (mediaAssociation.isMediaNameAsTranslation) {
            mediaAssociation.mediaAssociationTranslations = [];
          }
        });
        return this.dataService.createModuleGallery(module).pipe(
          tap((response: Module) => {
            this.setModule(response);
            this.setMediaAssociations(response.mediaAssociations);
          }));
      }
      case moduleType.QuizTrueFalse: {
        return this.dataService.createModuleQuizTrueFalse(module).pipe(
          tap((response: Module) => {
            this.setModule(response);
            this.setQuizQuestions(response.questions);
          }));
      }
      case moduleType.QuizMultipleChoice: {
        return this.dataService.createModuleQuizMultipleChoice(module).pipe(
          tap((response: Module) => {
            this.setModule(response);
            this.setQuizQuestions(response.questions);
          }));
      }
      case moduleType.QuizIncremental: {
        return this.dataService.createModuleQuizIncremental(module).pipe(
          tap((response: Module) => {
            this.setModule(response);
            this.setQuizQuestions(response.questions);
          }));
      }
      case moduleType.Custom: {
        const moduleCustomTemplateId = this.getModuleCustomTemplateId();
        if (moduleCustomTemplateId !== -1) {
          module.moduleCustomTemplateId = moduleCustomTemplateId;
        }
        return this.dataService.createModuleCustom(module).pipe(
          tap((response: Module) => {
            this.setModule(response);
          }));
        }
      case moduleType.Undefined: {
        return new Observable();
      }
    }
  }

  updateModule(isDuplication: boolean, mediaAssociations?: MediaAssociation[]): Observable<ApiResponse<Module>> {

    const module = this.getModule();
    module.mediaSynthesisId = module.mediaSynthesis?.id;
    module.mediaSynthesis = undefined;

    if (module.type === moduleType.Gallery && mediaAssociations) {
      module.mediaAssociations = this.getMediaAssociations();
    } else if (module.type === moduleType.QuizMultipleChoice || module.type === moduleType.QuizTrueFalse || module.type === moduleType.QuizIncremental) {
      const questions = structuredClone(this.getQuizQuestions());
      questions.forEach((question) => {
        question.mediaId = question.media?.id;
        question.media = undefined;
        if (module.type === moduleType.QuizMultipleChoice || module.type === moduleType.QuizIncremental) {
          question.answers.forEach((answer) => {
            answer.mediaId = answer.media?.id;
            answer.media = undefined;
            if (!question.answersWithImages) {
              answer.mediaId = undefined;
            }
          });
        }
      });
      module.questions = questions;
    } else if (module.type === moduleType.Custom) {
      const moduleCustomTemplateId = this.getModuleCustomTemplateId();
      if (moduleCustomTemplateId !== -1) {
        module.moduleCustomTemplateId = moduleCustomTemplateId;
      }
    }

    return this.dataService.updateModule(module).pipe(
      tap((response: ApiResponse<Module>) => {
        if (isDuplication === true) {
          // TODO: Implement duplication
          // this.duplicateModule(newModuleWithInfos);
        } else {
          if (response.errorCode === responseCode.SuccessfulUpdate) {
              this.setModule(response.data);
              if (response.data.type === this.enumModuleType.QuizMultipleChoice || response.data.type === this.enumModuleType.QuizTrueFalse || response.data.type === this.enumModuleType.QuizIncremental) {
              this.setQuizQuestions(response.data.questions);
              }
              if (response.data.type === moduleType.Gallery && response.data.mediaAssociations) {
                response.data.mediaAssociations.sort((a, b) => a.order - b.order);
                this.associatedMediaList.next(response.data.mediaAssociations);
              } else if (response.data.type === moduleType.Gallery) {
                this.associatedMediaList.next([]);
              }
          }
        }
      }));
  }

  navigateToNewModule(module: Module) {
    this.messageService.add({ severity: 'success', summary: 'Création réussie', detail: 'L\'activité a bien été créé.' });
    this.setModule(module);
    if (module.questions) {
      this.setQuizQuestions(module.questions);
    }
    this.navigate('module-management/' + module.id);
  }

  associateMedia(newMediaAssociation: MediaAssociation) {
    const newList: MediaAssociation[] = this.associatedMediaList.getValue();
    newList.push(newMediaAssociation);
    this.setMediaAssociations(newList);
  }

  navigate(url: string) {
    const projectName = this.authService.getProjectName();
    this.router.navigateByUrl(`${projectName}/${url}`);
  }
}
