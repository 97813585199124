import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { fileType } from 'app/Enumerations/file-type.enum';
import { Language } from 'app/Models/language';
import { Media } from 'app/Models/media';
import { MediaAssociation, MediaAssociationTranslation } from 'app/Models/module';
import { DataService } from 'app/Services/Data-Service/data.service';
import { ModuleService } from 'app/Services/Module-Service/module.service';

@Component({
  selector: 'app-module-gallery',
  templateUrl: './module-gallery.component.html',
  styleUrl: './module-gallery.component.css',
})
export class ModuleGalleryComponent implements OnInit {
  @Input() languagesSelected: Language[] = [];
  @Input() mediaList: Media[] = [];
  associatedMediaList: MediaAssociation[] = [];

  indexPopupOpened: number = -1;

  mediaVisualisationFromPage: boolean = false;

  fileBlob?: Blob;
  fileUrl?: string;

  isPopupVisible: boolean = false;

  //Forms
  @Input() multiLanguageForm!: FormGroup;
  mediaForm: FormGroup;
  items: FormArray | undefined;

  distanceBetweenCards: number = 50;

  constructor(
    private dataService: DataService,
    private fb: FormBuilder,
    private moduleService: ModuleService
  ) {
    this.mediaForm = this.fb.group({
      items: new FormArray([]),
    });
  }

  ngOnInit() {
    this.moduleService.associatedMediaListObservable.subscribe(list => {

      // TODO: improve this part to not recreate all the forms each time the list changes. A task is already in Asana.
      this.items?.clear();
      list.forEach((mediaAssociation, index) => {

        this.addFormItem();
        const formItem = this.items?.at(index);

        if (formItem) {
          const currentLanguageId = this.multiLanguageForm.get('currentLanguage')?.value;
          const indexCurrentLanguage = mediaAssociation.mediaAssociationTranslations.findIndex((mt) => mt.languageId === currentLanguageId);
          if (mediaAssociation.mediaAssociationTranslations[indexCurrentLanguage]) {
            formItem.get(['mediaName'])?.setValue(mediaAssociation.mediaAssociationTranslations[indexCurrentLanguage].name);
          }
          formItem.get(['is360'])?.setValue(mediaAssociation.is360);
          formItem.get(['order'])?.setValue(mediaAssociation.order);
          formItem.get('useMediaNameAsTranslation')?.setValue(mediaAssociation.isMediaNameAsTranslation);
          console.log('form item = ', formItem);

        }
      });

      this.associatedMediaList = list;
    });
  }

  show() {
    console.log('liste associations', this.associatedMediaList);
    console.log('liste form', this.items?.getRawValue());
  }

  createFormItem(): FormGroup {

    // console.log('create form item, item length = ', this.items?.length);
    const isLanguageAssigned = (this.multiLanguageForm.get('currentLanguage')?.value === 0);
    return this.fb.group({
      mediaName: [{ value: '', disabled: isLanguageAssigned }, Validators.required],
      is360: [false],
      order: [''],
      useMediaNameAsTranslation: [true]
    });
  }

  addFormItem(): void {
    // console.log('add form item avant create form item, item length = ', this.items?.length);

    this.items = this.mediaForm.get('items') as FormArray;
    // console.log('item, ', this.items.getRawValue());

    this.items.push(this.createFormItem());

    // console.log('add form item après create form item, item length = ', this.items?.length);
  }

  showPopupV2() {
    this.indexPopupOpened = this.associatedMediaList.length;
    this.isPopupVisible = true;
    this.mediaVisualisationFromPage = false;
  }

  showPopup(index: number, mediaVisualisationFromPage: boolean) {
    this.indexPopupOpened = index;
    this.isPopupVisible = true;
    if (mediaVisualisationFromPage === true) {
      this.mediaVisualisationFromPage = mediaVisualisationFromPage;

      if (this.associatedMediaList[index].media.fileType === fileType.Image || this.associatedMediaList[index].media.fileType === fileType.Pdf) {
        this.dataService.getFile(this.associatedMediaList[index].media).subscribe({
          next: (file) => {
            this.fileBlob = file;
            this.fileUrl = URL.createObjectURL(file);
          },
          error: (error) => {
            console.log(error.message);
          },
        });
      } else {
        this.fileUrl = this.dataService.streamFile(this.associatedMediaList[index].media);
      }
    }
  }

  associateMedia(media: Media) {

    const mediaAssociationTranslations: MediaAssociationTranslation[] = [];
    this.languagesSelected.forEach((language) => {
      mediaAssociationTranslations.push(new MediaAssociationTranslation(0, language.id, ''));
    });

    this.indexPopupOpened++;
    const newMediaAssociation: MediaAssociation = new MediaAssociation(0, media, false, this.indexPopupOpened, true, mediaAssociationTranslations); 
    
    this.moduleService.associateMedia(newMediaAssociation);
    this.closePopup();
  }

  removeMedia(index: number) {
    const indexRemoveFormItem = this.items?.getRawValue().findIndex(i => i.order === index + 1);

    for (let i = 0; i < this.associatedMediaList.length; i++) {
      const orderFromform = this.items?.at(i).get(['order'])?.getRawValue();

      if (orderFromform > index) {
        this.items
          ?.at(i)
          .get(['order'])
          ?.setValue(orderFromform - 1);
      }

      if (i > index) {
        this.associatedMediaList[i - 1] = this.associatedMediaList[i];
        this.associatedMediaList[i].order -= 1;
      }
    }

    if (indexRemoveFormItem !== undefined) {
      this.items?.removeAt(indexRemoveFormItem);
    }

    this.associatedMediaList.splice(this.associatedMediaList.length - 1, 1);
    this.moduleService.setMediaAssociations(this.associatedMediaList);
  }

  closePopup() {
    this.isPopupVisible = false;
    this.mediaVisualisationFromPage = false;
    this.indexPopupOpened = -1;
    this.fileBlob = undefined;
    this.fileUrl = undefined;
  }

  getHeightCard(): number {
    if (this.associatedMediaList && this.associatedMediaList.length > 0) {
      return this.associatedMediaList.length * this.distanceBetweenCards + 80;
    } else {
      return 90;
    }
  }

  saveMediaNameTranslation(event: MediaAssociationTranslation) {
    // The property translatedEntityId is used to pass the index of the mediaAssociation to the parent component
    const indexLanguage = this.associatedMediaList[event.translatedEntityId].mediaAssociationTranslations?.findIndex(mt => mt.languageId === event.languageId);
    if (indexLanguage !== -1 && this.associatedMediaList[event.translatedEntityId].mediaAssociationTranslations[indexLanguage]) {
      this.associatedMediaList[event.translatedEntityId].mediaAssociationTranslations[indexLanguage].name = event.name;
      this.moduleService.setMediaAssociations(this.associatedMediaList);
    }
  }

  addTranslations(index: number) {
    const mediaAssociationTranslations: MediaAssociationTranslation[] = [];
    this.languagesSelected.forEach((language) => {
      mediaAssociationTranslations.push(new MediaAssociationTranslation(0, language.id, ''));
    });
    this.associatedMediaList[index].mediaAssociationTranslations = mediaAssociationTranslations;
    this.moduleService.setMediaAssociations(this.associatedMediaList);
  }
}
