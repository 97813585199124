import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { displayValue, fileType } from 'app/Enumerations/file-type.enum';
import { MediaAssociation, MediaAssociationTranslation } from 'app/Models/module';
import { CheckboxChangeEvent } from 'primeng/checkbox';

@Component({
  selector: 'app-associated-media-card',
  templateUrl: './associated-media-card.component.html',
  styleUrl: './associated-media-card.component.css',
})
export class AssociatedMediaCardComponent implements OnInit {
  @Input() formArrayIndex!: number;
  @Input() mediaAssociation!: MediaAssociation;

  @Output() dragStartedEmitter: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Output() showAssociatedMediaEmitter: EventEmitter<number> = new EventEmitter<number>();
  @Output() deleteAssociatedMediaEmitter: EventEmitter<number> = new EventEmitter<number>();
  @Output() renameMediaAssociationEmitter: EventEmitter<MediaAssociationTranslation> = new EventEmitter<MediaAssociationTranslation>();
  @Output() addTranslationsEmitter: EventEmitter<number> = new EventEmitter<number>();

  //Forms
  @Input() multiLanguageForm!: FormGroup;
  @Input() mediaForm: FormGroup;
  @Input() items: FormArray | undefined;

  //Enumerations
  enumFileType = fileType;

  constructor(private fb: FormBuilder) {
    this.mediaForm = this.fb.group({
      items: new FormArray([]),
    });
  }

  ngOnInit() {
    this.multiLanguageForm.get('currentLanguage')?.valueChanges.subscribe(value => {
      
      if (value === 0) {
        console.log('Langue = 0, (pas de langue) => ', value);
        
        this.items?.at(this.formArrayIndex).get('mediaName')?.disable();
        this.items?.at(this.formArrayIndex).get('mediaName')?.setValue('');
      } else {
        console.log('la langue est différente de 0 => ', value);
        const indexCurrentLanguage = this.mediaAssociation.mediaAssociationTranslations.findIndex((mt) => mt.languageId === value);
        console.log('voici l\'index du language courant => ', indexCurrentLanguage);
        console.log('media association ', this.mediaAssociation.mediaAssociationTranslations[indexCurrentLanguage]);
        
        
        // if (this.mediaAssociation.mediaAssociationTranslations[indexCurrentLanguage]) {
        //   console.log('on set le media name, formArrayIndex = ', this.formArrayIndex);
          
        // }
        if (this.items?.at(this.formArrayIndex).get(['mediaName'])) {

          console.log('le form est instancie TABARNAK');
          this.items?.at(this.formArrayIndex).get(['mediaName'])?.setValue('caca ');
        } else {
          console.log('le form n\'est pas instancie');
          
        }
        const nameEsti: string = this.mediaAssociation.mediaAssociationTranslations[indexCurrentLanguage].name; 
        this.items?.at(this.formArrayIndex).get(['mediaName'])?.setValue(nameEsti);
        // this.items?.at(this.formArrayIndex).get('mediaName')?.enable();
      }
    });
  }

  displayFileType(type: number): string {
    return displayValue(type);
  }

  start(event: MouseEvent) {
    event.preventDefault();
    this.dragStartedEmitter.emit([event.clientY, event.offsetY, this.mediaAssociation.order]);
  }

  showPopup() {
    this.showAssociatedMediaEmitter.emit(this.mediaAssociation.order - 1);
  }

  removeMedia() {
    this.deleteAssociatedMediaEmitter.emit(this.mediaAssociation.order - 1);
  }

  saveMediaNameTranslation() {
    console.log('yo fdp');
    
    const mediaName = this.items?.at(this.formArrayIndex).get('mediaName')?.value;
    const languageId = this.multiLanguageForm.get('currentLanguage')?.value;
    // The property translatedEntityId is used to pass the index of the mediaAssociation to the parent component
    this.renameMediaAssociationEmitter.emit(new MediaAssociationTranslation(this.formArrayIndex, languageId, mediaName));
  }
  
  // addTranslations() {
  //   this.addTranslationsEmitter.emit(this.formArrayIndex);
  // }

  useMediaNameAsTranslation(event: CheckboxChangeEvent) {
    console.log('event', event);
    this.mediaAssociation.isMediaNameAsTranslation = event.checked;
  }

  show() {
    console.log(this.formArrayIndex);
  }
}
