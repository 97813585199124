import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModuleCustomTemplate } from 'app/Models/module';
import { ModuleService } from 'app/Services/Module-Service/module.service';

@Component({
  selector: 'app-module-custom',
  templateUrl: './module-custom.component.html',
  styleUrl: './module-custom.component.css'
})
export class ModuleCustomComponent implements OnInit {
  @Input() moduleForm: FormGroup;
  @Input() moduleCustomTemplates: ModuleCustomTemplate[] = [];

  constructor(private moduleService: ModuleService, private fb: FormBuilder) {
    this.moduleForm = this.fb.group({
      moduleCustomTemplateId: [''],
    });
  }

  ngOnInit() {
    this.moduleService.moduleCustomTemplateIdObservable.subscribe(id => {
      this.moduleForm.get('moduleCustomTemplateId')?.setValue(id);
    });
  }

  selectModuleTemplate(id: number) {
    this.moduleService.setModuleCustomTemplateId(id);
  }
}
