import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { entityType } from 'app/Enumerations/entity-type.enum';
import { getPublishStateValue, publishState } from 'app/Enumerations/publish-state.enum';
import { HomeDashboard } from 'app/Models/homeDashboard';
import { AuthService } from 'app/Services/Auth-Service/auth.service';
import { DataService } from 'app/Services/Data-Service/data.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css'],
})
export class HomePageComponent implements OnInit {
  dashboard!: HomeDashboard;
  showPointOfInterests: boolean[] = [];
  showModules: boolean[] = [];

  //Enumerations
  enumPublishState = publishState;
  enumEntityType = entityType;

  constructor(
    private authService: AuthService,
    private dataService: DataService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.dataService.getHomeDashboard().subscribe((dashboard) => {
      if (dashboard.itineraries) {
        dashboard.itineraries.forEach((itinerary) => {
          itinerary.pointOfInterests?.forEach((pointOfInterest) => {
            pointOfInterest.showModules = new Array(pointOfInterest.modules?.length).fill(false);
          });
        });
        this.setPageTitle();
      }

      this.dashboard = dashboard;
    });
  }

  pointOfInterestsCount(): number {
    let result = 0;
    this.dashboard.itineraries?.forEach((itinerary) => {
      if (itinerary.pointOfInterests?.length) result += itinerary.pointOfInterests?.length;
    });
    return result;
  }

  modulesCount(): number {
    let result = 0;
    this.dashboard.itineraries?.forEach((itinerary) => {
      if (itinerary.pointOfInterests) {
        itinerary.pointOfInterests.forEach((pointOfInterest) => {
          if (pointOfInterest.modules) result += pointOfInterest.modules?.length;
        });
      }
    });
    return result;
  }

  displayPointOfInterestState(state: number) {
    return getPublishStateValue(state);
  }

  getProjectName(): string {
    return this.authService.getProjectName();
  }

  setPageTitle() {
    document.title = "Accueil";
  }
}
