import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { filter } from 'rxjs';
import { NavigationEnd, Event, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [MessageService],
})
export class AppComponent {
  title = 'Smart Together';
  localesList = [
    { code: 'en', label: 'English' },
    { code: 'fr', label: 'Français' },
  ];

  displayNavbar: boolean = false;
  projectName: string = 'Hello';

  constructor(
    private router: Router,
  ) {
    // this.titleService.setTitle($localize`${this.title}`)

    // Check the route to display the navbar
    router.events.pipe(filter((e: Event | NavigationEnd): e is NavigationEnd => e instanceof NavigationEnd)).subscribe((e: NavigationEnd) => {
      if (e.urlAfterRedirects === '/login') {
        //TODO: change /login for a variable
        this.displayNavbar = false;
      } else {
        this.displayNavbar = true;
      }
    });
  }
}
