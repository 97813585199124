<div class="body">
  <div class="page">
    <div class="header">
      <div>
        <div class="itinerary-name-state">
          <h1 class="truncate-one-line">{{ itinerary?.identificationName || "Nouveau parcours" }}</h1>
          @if (itinerary) {
            <app-publication-state class="publication-state-margin single-line" [publicationState]="itinerary.state" [entityType]="enumEntityType.Itinerary" >
            </app-publication-state>
            @if (itinerary.state === enumPublishState.PublicationInProgress) {
              <div>
                <p-progressSpinner></p-progressSpinner>
              </div>
            }
          }
        </div>

        @if (itinerary?.publicationDate) {
          <p>Dernière publication: {{ itinerary?.publicationDate | date: "medium" }}</p>
        }
        @if (itinerary?.lastModificationDate) {
          <p>Dernière modification: {{ itinerary?.lastModificationDate | date: "medium" }}</p>
        }
      </div>

      @if (isCreation) {
        <button class="button" (click)="createItinerary()" title="Créer le parcours">Créer</button>
      } @else {
        <p-splitButton label="Sauvegarder" title="Sauvegarder le parcours" (onClick)="updateItinerary()" [model]="menuItems"></p-splitButton>
      }

    </div>

    <app-itinerary-configuration
      [informationsForm]="informationsForm"
      [selectedLanguages]="languagesSelected"
      [languagesList]="languagesList" 
      (languageChangedEmitter)="configLanguageChanged($event)">
    </app-itinerary-configuration>

    <app-itinerary-multilanguage-content
      [multiLanguageForm]="multiLanguageForm"
      [itineraryTranslation]="itineraryTranslation"
      [languagesSelected]="languagesSelected"
      (languageChangedEmitter)="languageChanged($event)"
      (saveLanguageEmitter)="saveLanguage()">
    </app-itinerary-multilanguage-content>

    <app-point-of-interest-list
      [pointOfInterestList]="itinerary?.pointOfInterests"
      [itineraryId]="itinerary?.id"
      (deletePointOfInterestEmitter)="showDialog(enumDialogType.Delete, enumEntityType.PointOfInterest, $event.id)"
      (navigateNewPointOfInterest)="navigateNewPointOfInterest()">
    </app-point-of-interest-list>
  </div>
</div>

<app-confirmation-popup [visible]="visible" [confirmTypeAction]="confirmTypeAction" [entityTypeAction]="entityTypeAction" (confirmActionEmitter)="confirmActionDialog($event)">
</app-confirmation-popup>

<app-publication-popup [publication]="lastPublication" [visible]="visiblePublication" (closePopupEmitter)="closePublicationPopup()"> </app-publication-popup>
