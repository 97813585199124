import { Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { displayValue } from 'app/Enumerations/file-type.enum';
import { MediaAssociation, MediaAssociationTranslation } from 'app/Models/module';

@Component({
  selector: 'app-associated-media-list',
  templateUrl: './associated-media-list.component.html',
  styleUrl: './associated-media-list.component.css',
})
export class AssociatedMediaListComponent {
  @Input() associatedMediaList: MediaAssociation[] = [];

  @Output() showAssociatedMediaEmitter: EventEmitter<number> = new EventEmitter<number>();
  @Output() deleteAssociatedMediaEmitter: EventEmitter<number> = new EventEmitter<number>();
  @Output() renameMediaAssociationEmitter: EventEmitter<MediaAssociationTranslation> = new EventEmitter<MediaAssociationTranslation>();
  @Output() addTranslationsEmitter: EventEmitter<number> = new EventEmitter<number>();

  //Forms
  @Input() multiLanguageForm!: FormGroup;
  @Input() mediaForm: FormGroup;
  @Input() items: FormArray | undefined;

  //Drag and drop properties
  dragged: boolean = false;
  indexDraggedItem: number = -1;

  mouseCoordinatesY: number = 0;

  mouseCoordMin!: number;
  mouseCoordMax!: number;

  cardPositionYbeforeMoving: number = 0;

  pointerComesFromOutside: boolean = false;

  distanceBetweenCards: number = 50;
  cardHeight!: number;

  @ViewChildren('dragItem', { read: ElementRef }) dragItems!: QueryList<ElementRef>;

  constructor(private fb: FormBuilder) {
    this.mediaForm = this.fb.group({
      items: new FormArray([]),
    });
  }

  displayFileType(type: number): string {
    return displayValue(type);
  }

  start(numberArray: number[], indexMediaAssociation: number) {
    this.dragged = true;
    this.indexDraggedItem = indexMediaAssociation;
    this.mouseCoordinatesY = numberArray[0];
    const element = this.dragItems.get(this.indexDraggedItem);

    if (element && this.associatedMediaList) {
      this.cardPositionYbeforeMoving = element.nativeElement.style.top;
      this.cardHeight = element.nativeElement.clientHeight;
      this.mouseCoordMin = this.mouseCoordinatesY - this.indexDraggedItem * this.distanceBetweenCards - numberArray[1];
      this.mouseCoordMax = this.mouseCoordinatesY + (this.associatedMediaList?.length - this.indexDraggedItem - 1) * this.distanceBetweenCards - numberArray[1] + this.cardHeight;
    }
  }

  move(event: MouseEvent) {
    event.preventDefault();
    const element = this.dragItems.get(this.indexDraggedItem);

    if (this.dragged && element && this.associatedMediaList) {
      const newPosY = this.mouseCoordinatesY - event.y;
      this.mouseCoordinatesY = event.y;
      if (this.pointerComesFromOutside == true && (this.mouseCoordinatesY < this.mouseCoordMin + this.cardHeight / 2 || this.mouseCoordinatesY > this.mouseCoordMax - this.cardHeight / 2)) {
        //Pointer already leaved the list and is not able to drag yet
      } else if (this.mouseCoordinatesY < this.mouseCoordMin) {
        // Pointer has left the list from above
        this.pointerComesFromOutside = true;
        element.nativeElement.style.top = 0 + 'px';
      } else if (this.mouseCoordinatesY > this.mouseCoordMax) {
        // Pointer has left the list from below
        this.pointerComesFromOutside = true;
        element.nativeElement.style.top = this.associatedMediaList.length * this.distanceBetweenCards - this.distanceBetweenCards + 'px';
      } else if (element.nativeElement.offsetTop - newPosY >= 0 && element.nativeElement.offsetTop - newPosY <= (this.dragItems.length - 1) * this.distanceBetweenCards) {
        // Normal drag
        this.pointerComesFromOutside = false;
        element.nativeElement.style.top = element.nativeElement.offsetTop - newPosY + 'px';
      }
    }
  }

  stop(event: MouseEvent) {
    event.preventDefault();

    const element = this.dragItems.get(this.indexDraggedItem);

    if (this.dragged && element) {
      const droppedCoordinatesY = element.nativeElement.offsetTop;

      const dropIndex = Math.round(droppedCoordinatesY / this.distanceBetweenCards);
      const formArray = this.items?.getRawValue();

      if (this.indexDraggedItem != dropIndex && this.associatedMediaList && formArray) {
        const differenceIndex = dropIndex - this.indexDraggedItem;

        if (Math.sign(differenceIndex) == 1) {
          for (let i = 0; i < differenceIndex; i++) {
            this.associatedMediaList[this.indexDraggedItem + i + 1].order -= 1;
            const order = this.associatedMediaList[this.indexDraggedItem + i + 1].order;
            const index = formArray.findIndex((p) => p.order === order + 1);
            this.items?.at(index).get(['order'])?.setValue(order);
          }
        } else {
          for (let i = 0; i > differenceIndex; i--) {
            this.associatedMediaList[this.indexDraggedItem + i - 1].order += 1;
            const order = this.associatedMediaList[this.indexDraggedItem + i - 1].order;
            const index = formArray.findIndex((p) => p.order === order - 1);
            this.items?.at(index).get(['order'])?.setValue(order);
          }
        }

        const index = formArray.findIndex((p) => p.order === this.indexDraggedItem + 1);
        this.items
          ?.at(index)
          .get(['order'])
          ?.setValue(dropIndex + 1);

        this.associatedMediaList[this.indexDraggedItem].order = dropIndex + 1;
        this.associatedMediaList.sort((a, b) => a.order - b.order);
      } else {
        element.nativeElement.style.top = this.cardPositionYbeforeMoving;
      }

      this.dragged = false;
      this.indexDraggedItem = -1;
    }
  }

  // TODO: Improve the way to handle the situation
  onScrollEnd() {
    const element = this.dragItems.get(this.indexDraggedItem);

    if (this.dragged && element && this.associatedMediaList) {
      element.nativeElement.style.top = this.indexDraggedItem * this.distanceBetweenCards + 'px';

      this.dragged = false;

      this.indexDraggedItem = -1;
    }
  }

  getHeightCard(): number {
    if (this.associatedMediaList && this.associatedMediaList.length > 0) {
      return this.associatedMediaList.length * this.distanceBetweenCards + 100;
    } else {
      // return 180;
      return 150;
    }
  }

  showPopup(index: number) {
    this.showAssociatedMediaEmitter.emit(index);
  }

  removeMedia(number: number) {
    this.deleteAssociatedMediaEmitter.emit(number);
  }

  saveMediaNameTranslation(event: MediaAssociationTranslation) {
    // The property translatedEntityId is used to pass the index of the mediaAssociation to the parent component
    this.renameMediaAssociationEmitter.emit(event);
  }

  addTranslations(index: number) {
    this.addTranslationsEmitter.emit(index);
  }
}
