import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Media } from 'app/Models/media';
import { DataService } from 'app/Services/Data-Service/data.service';
import { displayValue, fileType } from 'app/Enumerations/file-type.enum';
import { confirmDialogType } from 'app/Enumerations/confirm-dialog-type.enum';
import { entityType } from 'app/Enumerations/entity-type.enum';
import { MessageService } from 'primeng/api';
import { responseCode } from 'app/Enumerations/response-code.enum';
import { UploadFilesService } from 'app/Services/Upload-Service/upload-files.service';

@Component({
  selector: 'app-document-library-page',
  templateUrl: './document-library-page.component.html',
  styleUrls: ['./document-library-page.component.css'],
})
export class DocumentLibraryPageComponent implements OnInit {
  mediaList: Media[] = [];
  selectedMediaList: Media[] = [];
  filteredMediaList: Media[] = [];

  mediaToDisplay?: Media = undefined;

  filesToUpload?: File;

  fileBlob?: Blob;
  fileUrl: string = '';

  filterValue: string = '';

  mediaManagementPopupVisible: boolean = false;

  first = 0;

  clickEventCoordinates: number[] = [0, 0];

  // Confirmation dialog attributes
  confirmationPopupVisible: boolean = false;
  confirmTypeAction!: number;
  entityTypeAction!: number;
  mediaIdToProcess!: number;
  multipleDeletion!: boolean;

  //Add new files to library temporary list
  // temporary

  //Enumerations
  enumDialogType = confirmDialogType;
  enumEntityType = entityType;

  constructor(
    private dataService: DataService,
    private messageService: MessageService,
    private uploadFilesService: UploadFilesService,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    this.dataService.getMedias().subscribe({
      next: (medias) => {
        this.mediaList = medias;
        this.filteredMediaList = medias;
      },
      error: (error) => {
        console.log(error.message);
      },
    });

    this.uploadFilesService.newMedia$.subscribe((media) => {
      if (media) {
        this.addToMediaList(media);
      }
    });
    this.setPageTitle();
  }

  closePopup() {
    this.mediaManagementPopupVisible = false;
    this.mediaToDisplay = undefined;
    this.fileBlob = undefined;
    this.fileUrl = "";
  }

  displayFileType(type: number): string {
    return displayValue(type);
  }

  displayMedia(event: MouseEvent, media: Media) {
    if (event.x === this.clickEventCoordinates[0] && event.y === this.clickEventCoordinates[1]) {
      this.mediaToDisplay = media;
      this.mediaManagementPopupVisible = true;
      this.uploadFilesService.setVisiblePopup(true);

      if (media.fileType === fileType.Image || media.fileType === fileType.Pdf) {
        this.dataService.getFile(media).subscribe({
          next: (file) => {
            this.fileBlob = file;
            this.fileUrl = URL.createObjectURL(file);
          },
          error: (error) => {
            console.log(error.message);
          },
        });
      } else {
        // console.log('Ca stream');
        // streamVideo(): void {
        //   this.videoService.streamVideo().subscribe(blob => {
        //     this.videoUrl = window.URL.createObjectURL(blob);
        //   }, error => {
        //     console.error('Failed to stream video', error);
        //   });
        // }
        this.fileUrl = this.dataService.streamFile(media);

        // this.dataService.streamFile(media).subscribe({
        //   next: (blob) => {

        //     this.fileBlob = blob;
        //     this.fileUrl = window.URL.createObjectURL(blob);
        //   },
        //   error: (error) => {
        //     console.log(error.message);
        //   },
        // });
      }

    }
  }

  applyGlobalFilter() {
    this.filteredMediaList = this.mediaList;
    this.filteredMediaList = this.filteredMediaList.filter((item) => {
      return this.matchesFilter(item, this.filterValue);
    });
  }

  matchesFilter(item: Media, filterValue: string): boolean {
    filterValue = filterValue.toLowerCase();

    const formattedDate = this.datePipe.transform(item.createdAt, 'short');

    const nameMatches = item.name.toLowerCase().includes(filterValue);

    const dateMatches = formattedDate?.includes(filterValue)

    const fileTypeMatches = displayValue(item.fileType).toLowerCase().includes(filterValue);

    const extensionMatches = item.extension.toLowerCase().includes(filterValue);

    return nameMatches || dateMatches || fileTypeMatches || extensionMatches;
  }

  addToMediaList(newMedia: Media) {
    // this.mediaList.push(newMedia);
    this.mediaList = [...this.mediaList, newMedia]; //TODO: add page https://forum.primefaces.org/viewtopic.php?t=66051 to oneNote
    this.filteredMediaList = this.mediaList;
  }

  displayFileSize(size: number): string {
    if (size < 1000) {
      return '' + size + ' o';
    } else if (size < 1_000_000) {
      return '' + (size / 1024).toFixed(1) + ' Ko';
    } else if (size < 1_000_000_000) {
      return '' + (size / 1_048_576).toFixed(1) + ' Mo';
    } else {
      return '' + (size / 1_073_741_824).toFixed(1) + ' Go';
    }
  }

  showConfirmationPopup(mediaId: number, multipleDeletion: boolean) {
    if (multipleDeletion == true && this.selectedMediaList.length == 0) {
      this.messageService.clear();
      this.messageService.add({ severity: 'warn', summary: 'Aucun média séletionné', detail: 'Sélectionnez un ou plusieurs médias pour effectuer cette action.' });
    } else {
      this.mediaIdToProcess = mediaId;
      this.multipleDeletion = multipleDeletion;
      this.confirmTypeAction = this.enumDialogType.Delete;
      this.entityTypeAction = this.enumEntityType.Media;
      this.confirmationPopupVisible = true;
    }
  }

  confirmActionDialog(confirmAction: boolean) {
    this.confirmationPopupVisible = false;
    if (confirmAction == true) {
      if (this.multipleDeletion == false) {
        this.deleteFile();
      } else {
        this.deleteMultipleFiles();
      }
    }
  }

  deleteFile() {
    this.dataService.deleteMedia(this.mediaIdToProcess).subscribe({
      next: (result) => {
        if (result.errorCode === responseCode.MediaSuppressed) {
          this.mediaList = this.mediaList.filter((media) => media.id !== this.mediaIdToProcess);
          this.filteredMediaList = this.mediaList;
          this.messageService.clear();
          this.messageService.add({ severity: 'success', summary: 'Média supprimé', detail: result.errorMessage });
        } else {
          this.messageService.clear();
          this.messageService.add({ severity: 'error', summary: 'Suppression impossible', detail: result.errorMessage });
        }
      },
      error: (error) => {
        console.log(error.message);
      },
    });
  }

  renameMedia(media: Media) {
    const indexMediaToRename = this.mediaList.map((e) => e.id).indexOf(media.id);
    this.mediaList[indexMediaToRename].name = media.name;
  }

  deleteMultipleFiles() {
    let deleteFilesCount = 0;
    let errorHappened = false;

    this.selectedMediaList.forEach((mediaToDelete) => {
      this.dataService.deleteMedia(mediaToDelete.id).subscribe({
        next: (result) => {
          if (result.errorCode === responseCode.MediaSuppressed) {
            this.mediaList = this.mediaList.filter((media) => media.id !== mediaToDelete.id);
            this.filteredMediaList = this.mediaList;
          } else {
            errorHappened = true;
          }
          deleteFilesCount++;

          if (deleteFilesCount == this.selectedMediaList.length) {
            this.messageService.clear();
            this.selectedMediaList = [];
            if (errorHappened === true)
              this.messageService.add({ severity: 'warn', summary: 'Médias partiellement supprimés', detail: 'Dû à une erreur technique, les médias ont été partiellement supprimés.' });
            else this.messageService.add({ severity: 'success', summary: 'Médias supprimés', detail: 'Les médias ont bien été supprimés.' });
          }
        },
        error: (error) => {
          console.log(error.message);
          errorHappened = true;
          deleteFilesCount++;
        },
      });
    });
  }

  visibleMediaManagementPopup() {
    this.mediaManagementPopupVisible = true;
    this.uploadFilesService.setVisiblePopup(true);
  }

  clickOnArray(event: MouseEvent) {
    this.clickEventCoordinates = [event.x, event.y];
  }

  setPageTitle() {
    document.title = "Bibliothèque";
  }

  // mov mp4

  // mp3 wav aiff

  // jpg png jpeg

  // webroot folder where to store files
}
